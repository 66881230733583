import axios from "axios";
import * as Configs from "../config";
import { errorHandling } from "./api";

export class RanksStore {
  async getRanks() {
    const url = `${Configs.API_URL}/v2/yokai/ranks`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_RANKS = "ranksStore";
