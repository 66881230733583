import { LyxenButton } from "./lyxen-button";
import { openErrorNotification } from "./notification";
import Web3 from "web3";

export const LyxenDisconnect = (props: any) => {
  const { setAddress } = props;

  async function connect() {
    // @ts-ignore: Unreachable code error
    const web3 = new Web3(window?.lukso);
    try {
      const accounts: any = await web3.eth.requestAccounts();
      const address = accounts[0];
      console.log("Account connected:", address);
      setAddress(address);
    } catch (error) {
      // handle connection error
      console.log("Error in connecting account:", error);
      openErrorNotification("Please try to connect again later");
    }
  }

  return (
    <div className="w-full flex-1 flex flex-col justify-center items-center">
      {/* Logo */}
      <div className="relative">
        <h1 className="glowing-light text-[100px]">Lyxen</h1>
        <img
          src="/assets/home page logo-8.png"
          alt="logo"
          className="absolute top-[-160px]"
        />
      </div>

      {/* Banner */}
      <img src="/assets/homepage banner-8.png" alt="banner" className="" />

      {/* Login Button */}
      <div className="mt-[50px]">
        <LyxenButton txt={"+ Connect UP!"} action={() => connect()} />
      </div>
    </div>
  );
};
