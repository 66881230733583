export const LyxenButton = (props: any) => {
  const { txt = "Btn", action } = props;
  return (
    <div
      className="bg-black w-[211px] min-h-[60px] h-[60px] relative z-[5] flex flex-col items-center justify-center cursor-pointer border-[2px] border-[#fff] rounded-full"
      onClick={action}
    >
      <p>{txt}</p>
    </div>
  );
};
