import { useCallback, useEffect, useState } from "react";
import { LyxenDisconnect } from "./lyxen-disconnect";
import { LyxenMyCollection } from "./lyxen-my-collection";
import { useProfileStore } from "src/stores";
import { openWarningNotification } from "./notification";

export const LyxenContainer = (props: any) => {
  const { selectedData, setSelectedData, setLoading } = props;

  const [address, setAddress] = useState(null);
  const [data, setData] = useState(null);

  const { getProfile, getProfileDetailV2 } = useProfileStore();

  const getProfileCb = useCallback(
    async (address) => {
      setLoading(true);
      const result = await getProfile(address);
      setLoading(false);
      if (!result)
        return openWarningNotification("Cannot get Profile, please try again");
      setData(result);
    },
    [getProfile, setLoading]
  );

  const getProfileDetailCb = useCallback(
    async (tokenHex) => {
      setLoading(true);
      const result = await getProfileDetailV2(tokenHex);
      setLoading(false);
      if (!result)
        return openWarningNotification(
          "Cannot get Profile Detail, please try again"
        );
      setSelectedData(result);
    },
    [getProfileDetailV2, setLoading, setSelectedData]
  );

  useEffect(() => {
    if (!address) return;

    getProfileCb(address);
  }, [address, getProfileCb]);

  return (
    <div
      className="w-full p-[50px] min-w-[400px] h-full min-h-screen flex flex-col items-start justify-start cursor-pointer relative"
      style={{
        background: `url('/assets/bg-8.png')`,
        backgroundSize: "cover",
      }}
    >
      {!address && <LyxenDisconnect setAddress={setAddress} />}
      {address && (
        <LyxenMyCollection
          data={data}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setAddress={setAddress}
          getProfileDetailCb={getProfileDetailCb}
        />
      )}
    </div>
  );
};
