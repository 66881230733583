import axios from "axios";
import * as Configs from "../config";
import { errorHandling } from "./api";

export class ProfileStore {
  async getProfile(address: string) {
    const url = `${Configs.API_URL}/v2/yokai/profile?address=${address}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"]["tokens"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfileDetail(tokenId: string) {
    const url = `${Configs.API_URL}/v1/yokai/profile-detail?tokenId=${tokenId}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getProfileDetailV2(tokenHex: string) {
    const url = `${Configs.API_URL}/v2/yokai/profile-detail?tokenHex=${tokenHex}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_PROFILE = "profileStore";
