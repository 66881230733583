import { useCallback, useEffect, useState } from "react";
import { useProfileStore, useRanksStore } from "src/stores";
import { openWarningNotification } from "./notification";

export const LyxenRankContainer = (props: any) => {
  const { selectedData, setSelectedData, setLoading } = props;
  const [ranks, setRanks] = useState(null);

  const { getRanks } = useRanksStore();
  const { getProfileDetailV2 } = useProfileStore();

  const getRanksCb = useCallback(async () => {
    setLoading(true);
    const result = await getRanks();
    setLoading(false);
    if (!result)
      return openWarningNotification("Cannot get Ranks, please try again");
    setRanks(result);
  }, [getRanks, setLoading]);

  const getProfileDetailCb = useCallback(
    async (tokenHex) => {
      setLoading(true);
      const result = await getProfileDetailV2(tokenHex);
      setLoading(false);
      if (!result)
        return openWarningNotification(
          "Cannot get Profile Detail, please try again"
        );
      setSelectedData(result);
    },
    [getProfileDetailV2, setLoading, setSelectedData]
  );

  useEffect(() => {
    getRanksCb();
  }, [getRanksCb]);

  const getRankName = (rank: number) => {
    if (!rank) return;
    if (rank <= 100) return "Mystical";
    if (rank > 100 && rank <= 400) return "Epic";
    if (rank > 400 && rank <= 900) return "Rare";
    if (rank > 900 && rank <= 1500) return "Uncommon";
    return "Common";
  };

  const getRankColor = (rank: number) => {
    if (!rank) return "#000";
    if (rank <= 100) return "#f152ff";
    if (rank > 100 && rank <= 400) return "#fdd251";
    if (rank > 400 && rank <= 900) return "#ff9602";
    if (rank > 900 && rank <= 1500) return "#11fdbe";
    return "#000";
  };

  return (
    <div
      className="w-full p-[50px] min-w-[400px] h-full min-h-screen flex flex-col items-start justify-start cursor-pointer relative"
      style={{
        background: `url('/assets/bg-8.png')`,
        backgroundSize: "contain",
        backgroundRepeat: "repeat-y",
      }}
    >
      <div className="w-full flex-1 flex flex-col justify-start items-center relative">
        {/* Logo */}
        <div className="relative mt-[160px]">
          <h1 className="glowing-light text-[100px]">Lyxen</h1>
          <img
            src="/assets/home page logo-8.png"
            alt="logo"
            className="absolute top-[-160px]"
          />
        </div>

        {/* Selected Image */}
        {selectedData && (
          <div className="rounded-[30px] border-[2px] border-[#f155e3] overflow-hidden">
            <img src={selectedData?.image} alt="img" className="w-[300px]" />
          </div>
        )}

        {/* Ranks */}
        <dl className="w-full flex flex-row justify-between items-center pb-[0px] lg:pb-[0px]">
          <dt className="w-[100px] ml-[20px]">
            <p className={$titleStyle}>Rank</p>
          </dt>
          <dt className="flex-3 w-[300px] ml-[50px]">
            <p className={`${$titleStyle} !text-left`}>Token #ID</p>
          </dt>
          <dt className="flex-1 w-[100px] mr-[20px]">
            <p className={`${$titleStyle} !text-right`}>Type</p>
          </dt>
        </dl>
        {ranks &&
          Object.entries(ranks).map(([key, value]: any) => {
            return (
              <div
                key={key}
                className={`${$rowStyle} group transition-all duration-300 cursor-pointer`}
                onClick={() => {
                  getProfileDetailCb(key);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                <dl
                  className={`${$tableRow} group-hover:bg-[#eee] transition-all duration-300 shadow-none group-hover:shadow-[0px_0px_15px] group-hover:shadow-primary`}
                >
                  <dd className="w-[100px] ml-[20px]">
                    <p className={`${$labelStyle}`}>{value?.rank}</p>
                  </dd>

                  <dd className="flex-3 w-[300px] ml-[50px]">
                    <p className={`${$labelStyle} !text-left`}>
                      {value?.assetDetails?.tokenName}
                    </p>
                  </dd>

                  <dd className="flex-1 w-[100px] mr-[20px]">
                    <p
                      className={`${$labelStyle} !text-right`}
                      style={{ color: getRankColor(value?.rank) }}
                    >
                      {getRankName(value?.rank)}
                    </p>
                  </dd>
                </dl>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const $titleStyle = "text-[#fff] font-[500] text-center";
const $labelStyle = "text-[#000] font-[500] text-center";
const $rowStyle = "mt-[5px] p-[5px] rounded-[15px] w-full";
const $tableRow =
  "w-full flex flex-row justify-between items-center text-center rounded-[15px] py-[3px] bg-white h-[44px]";
