import { history } from "src/stores";

export const LyxenMenu = (props: any) => {
  const { selectedData } = props;
  let tokenId = null;
  let skin = null;
  let hair = null;
  let attire = null;
  let bg = null;

  if (selectedData) {
    tokenId = selectedData?.name;
    skin = selectedData?.rarity.filter((item: any) => item.key === "SKIN")[0];
    hair = selectedData?.rarity.filter((item: any) => item.key === "HAIR")[0];
    attire = selectedData?.rarity.filter(
      (item: any) => item.key === "ATTIRES"
    )[0];
    bg = selectedData?.rarity.filter(
      (item: any) => item.key === "Background"
    )[0];
  }
  return (
    <div className="px-[50px] h-full min-h-screen flex flex-col items-start justify-center cursor-pointer relative">
      {/* Lyxen Logo */}
      <div
        className="absolute top-[100px] right-[50px] cursor-pointer"
        onClick={() => history.push("/")}
      >
        <h1 className="glowing-light text-[50px]">Lyxen</h1>
        <img
          src="/assets/home page logo-8.png"
          alt="logo"
          className="absolute top-[-60px] w-[150px]"
        />
      </div>

      {/* Traits Title */}
      <div className="flex flex-row items-center">
        <p className="glowing-light">Traits</p>
        <img
          src="/assets/trait line-8.png"
          alt="trait-line"
          className="ml-[30px]"
        />
      </div>

      {/* Traits Menu */}
      <div className="flex flex-col mt-[30px]">
        {/* Token ID */}
        <div className={$iconWrap}>
          <img src="/assets/icon1-8.png" alt="icon" className={$iconStyle} />
          <p className={$label}>Token ID</p>

          {tokenId && (
            <div className={$traitsWrapper}>
              <p className={$traits}>{tokenId}</p>
            </div>
          )}
        </div>
        {/* Skin */}
        <div className={$iconWrap}>
          <img src="/assets/icon2-8.png" alt="icon" className={$iconStyle} />
          <p className={$label}>Skin</p>

          {skin && (
            <div className={$traitsWrapper}>
              <p className={$traits}>
                {skin?.trait} - {skin?.occurrence}
              </p>
            </div>
          )}
        </div>
        {/* Hair */}
        <div className={$iconWrap}>
          <img src="/assets/icon3-8.png" alt="icon" className={$iconStyle} />
          <p className={$label}>Hair</p>

          {hair && (
            <div className={$traitsWrapper}>
              <p className={$traits}>
                {hair?.trait} - {hair?.occurrence}
              </p>
            </div>
          )}
        </div>
        {/* Attire */}
        <div className={$iconWrap}>
          <img src="/assets/icon4-8.png" alt="icon" className={$iconStyle} />
          <p className={$label}>Attire</p>

          {attire && (
            <div className={$traitsWrapper}>
              <p className={$traits}>
                {attire?.trait} - {attire?.occurrence}
              </p>
            </div>
          )}
        </div>
        {/* Bg */}
        <div className={$iconWrap}>
          <img src="/assets/icon5-8.png" alt="icon" className={$iconStyle} />
          <p className={$label}>Background</p>

          {bg && (
            <div className={$traitsWrapper}>
              <p className={$traits}>
                {bg?.trait} - {bg?.occurrence}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Rarity */}
      {selectedData?.rank && (
        <div className="w-full mt-[30px]">
          <div className="flex flex-row justify-end items-start mr-[30px]">
            <img
              src="/assets/rarity-8.png"
              alt="trait-line"
              className="mr-[15px]"
            />
            <p className="glowing-light">
              Rarity <br />
              Score
            </p>
          </div>
          <div className="w-full flex flex-row justify-end">
            <div
              className={`${$traitsWrapper} mt-[15px] w-[150px] !min-w-[150px] text-center`}
            >
              <p className={$traits}>{selectedData?.rank} / 2626</p>
            </div>
          </div>
        </div>
      )}

      {/* Social Icons */}
      <div className="flex flex-row absolute bottom-[50px] left-[50px]">
        <div className="flex flex-row flex-1">
          <img
            src="/assets/X-8.png"
            alt="icon"
            className={$socialIconStyle}
            onClick={() =>
              window.open("https://twitter.com/CanvasLandWeb3", "_blank")
            }
          />
          <img
            src="/assets/CG-8.png"
            alt="icon"
            className={$socialIconStyle}
            onClick={() =>
              window.open("https://app.cg/c/CQJ9OneI8P/", "_blank")
            }
          />
        </div>
        <p
          className="glowing-light cursor-pointer"
          onClick={() => history.push("/ranks")}
        >
          Ranks
        </p>
      </div>
    </div>
  );
};

const $iconWrap = "flex flex-row items-center mt-[30px]";
const $iconStyle = "mr-[30px] w-[20px]";
const $socialIconStyle = "mr-[15px] w-[30px]";
const $label = "min-w-[200px]";
const $traitsWrapper =
  "min-w-[200px] py-[5px] px-[10px] bg-[#1a1a1a] rounded-[10px]";
const $traits = "text-[14px]";
