// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { RegistrationStore, STORE_REGISTRATION } from "./registration";
import { ProfileStore, STORE_PROFILE } from "./profile";
import { RanksStore, STORE_RANKS } from "./ranks";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_REGISTRATION]: new RegistrationStore(),
    [STORE_PROFILE]: new ProfileStore(),
    [STORE_RANKS]: new RanksStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useRegistrationStore() {
  const { registrationStore } = useStores();
  return registrationStore;
}

export function useProfileStore() {
  const { profileStore } = useStores();
  return profileStore;
}

export function useRanksStore() {
  const { ranksStore } = useStores();
  return ranksStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
