import { LyxenMenu } from "src/components/lyxen-menu";
import { Container, LoadingIcon } from "../components";
import { LyxenContainer } from "src/components/lyxen-container";
import { useState } from "react";

export const Home = (props: any) => {
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <div className="min-h-screen h-full bg-[#000] flex relative flex flex-row">
        {/* Left Menu */}
        <LyxenMenu selectedData={selectedData} />
        {/* Content */}
        <LyxenContainer
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setLoading={setLoading}
        />

        {/* Loading */}
        {loading && (
          <div className="w-screen h-screen fixed flex justify-center items-center top-0 left-0 z-[999]">
            <div className="relative z-[5]">
              <LoadingIcon />
            </div>
            <div className="bg-black/60 w-screen h-screen absolute top-0 left-0" />
          </div>
        )}
      </div>
    </Container>
  );
};
