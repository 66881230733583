import { LyxenButton } from "./lyxen-button";

export const LyxenMyCollection = (props: any) => {
  const {
    data,
    selectedData,
    setSelectedData,
    setAddress,
    getProfileDetailCb,
  } = props;
  return (
    <div className="w-full flex-1 flex flex-col justify-center items-center relative">
      {/* Disconnect Button */}
      <div className="absolute top-[0px] right-[0px]">
        <LyxenButton
          txt={"Disconnect"}
          action={() => {
            setAddress(null);
            setSelectedData(null);
          }}
        />
      </div>

      {!selectedData && (
        <div className="flex flex-row items-end">
          {/* Logo */}
          <div className="relative">
            <h1 className="glowing-light text-[100px]">Lyxen</h1>
            <img
              src="/assets/home page logo-8.png"
              alt="logo"
              className="absolute top-[-160px]"
            />
          </div>

          {/* Banner */}
          <img src="/assets/homepage banner-8.png" alt="banner" className="" />
        </div>
      )}

      {selectedData && (
        <div className="rounded-[30px] border-[2px] border-[#f155e3] overflow-hidden">
          <img src={selectedData?.image} alt="img" className="w-[300px]" />
        </div>
      )}

      {/* My Collection Button */}
      <div className="mt-[50px]">
        <LyxenButton
          txt={"My Collection"}
          action={() => setSelectedData(null)}
        />
      </div>
      {/* Demons List */}
      {data && data.length > 0 && (
        <div className="mt-[50px] grid grid-cols-5 gap-[15px]">
          {data.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-col items-center justify-center max-w-[200px] px-[20px]"
                onClick={() => getProfileDetailCb(item?.tokenHex)}
              >
                <img
                  src={item?.image}
                  alt="img"
                  className="rounded-[30px] w-[150px]"
                />
                <p className="mt-[15px] text-center">#{item?.tokenId}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
